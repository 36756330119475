import { Component, OnInit } from '@angular/core';
import { UserService } from '../shared/services/user.service';
import { MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { LoginConfig } from './login.config';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public showSpinner: boolean;
  public username: string;
  public password: string;

  constructor(public snackBar: MatSnackBar,
    private userService: UserService,
    private router: Router) { }

  public ngOnInit(): void {
    this.showSpinner = false;
  }

  public login(): void {
    if (this.validateCredentials()) {
      this.showSpinner = true;
      this.userService.login(this.username, this.password, (result: any) => {
        if (result.success && result.user) {
          this.router.navigate(['/dashboard']);
        } else {
          this.openSnackBar(LoginConfig.validationMessages.wrongUserOrEmail, "ok");
        }
      });
    }
    this.showSpinner = false;
  }

  public validateLogin(event: KeyboardEvent): void {
    if (event.keyCode === 13) {
      this.login();
    }
  }

  private validateCredentials(): boolean {
    if (!this.username || this.username.trim() === "") {
      this.openSnackBar(LoginConfig.validationMessages.userRequired, "ok");
      return false;
    }

    if (!this.password || this.password.trim() === "") {
      this.openSnackBar(LoginConfig.validationMessages.passwordRequired, "ok");
      return false;
    }
    return true;
  }

  public openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }

}
