import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import {
    MatTableDataSource,
    MatDialog,
    MatPaginator,
    MatSort,
    MatSnackBar
} from '@angular/material';
import { YescancelDialogComponent } from '../yescancel-dialog/yescancel-dialog.component';
import { FileUploadConfig } from "./file-upload.config";
import { UserService } from 'src/app/shared';
import { forEach } from '@angular/router/src/utils/collection';
import { invalid } from '@angular/compiler/src/render3/view/util';
import { nextContext } from '@angular/core/src/render3';

@Component({
    selector: 'soli-file-upload',
    templateUrl: './file-upload.component.html',
    styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit {
    public currentFile: Seguimiento;
    public uploadedFiles: [];
    public showSpinner: boolean;

    @ViewChild('fileInputManager') public fileInput: ElementRef;

    constructor(
        public dialog: MatDialog,
        public snackBar: MatSnackBar,
        private userService: UserService
    ) {
    }

    public ngOnInit(): void {
        this.uploadedFiles = [];
        this.showSpinner = false;
        this.currentFile = { description: null, user_id: 0 };
    }

    public onUploadPhoto(): void {
        this.fileInput.nativeElement.click();
    }

    public fileUpload(): void {
        const files = this.fileInput.nativeElement.files;
        function bindedOnload(e: any) {
            this.uploadedFiles.push(e.target.result);
        }

        for (let i = 0; i < files.length; i++) {
            let reader = new FileReader();
            if (this.validateMaxFileSize(files[i].size)) {
                this.openSnackBar(`${FileUploadConfig.generalMessages.fileExceedsSize} ${files[i].name}`, "ok");
                break;
            } else {
                reader.onload = bindedOnload.bind(this);
                reader.readAsDataURL(files[i]);
            }
        }
    }

    public onUploadFile(): void {
        this.showSpinner = true;
        if (this.uploadedFiles.length > 0) {
            if (this.currentFile.description === null || this.currentFile.description === "") {
                this.openSnackBar("Por favor digite un comentario para el seguimiento", "ok");
                this.showSpinner = false;
                return;
            }
            this.userService.uploadSeguimiento(this.currentFile, (res: { success: boolean, insertId: number }) => {
                if (res.success) {
                    this.next(this.uploadedFiles, 0, res.insertId);
                } else {
                    this.openSnackBar("Ocurrió un problema al guardar su seguimieto por favor contacte al administrador", "ok");
                }
            });
        } else {
            this.openSnackBar(FileUploadConfig.generalMessages.notFileSelected, "ok");
            this.showSpinner = false;
        }
    }

    private openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
            duration: 5000,
        });
    }

    private clearFile(): void {
        this.currentFile = { description: null, user_id: 0 };
        this.uploadedFiles = [];
    }

    private validateMaxFileSize(filesize: number): boolean {
        return Math.round(filesize / 1024) > FileUploadConfig.maxFileSize;
    }

    private next(array, idx, idSeg) {
        if (idx !== array.length) {
            console.log(array[idx]);
            this.userService.uploadSeguimientoFile(array[idx], idSeg, (resFile: { success: boolean, insertId: number }) => {
                if (resFile.success) {
                    this.next(array, idx + 1, idSeg);
                } else {
                    this.openSnackBar("Ocurrió un problema al guardar su seguimieto por favor contacte al administrador", "ok");
                    this.clearFile();
                    this.showSpinner = false;
                    return;
                }
            });
        } else {
            this.openSnackBar("Su Seguimiento ha sido guardado con exito", "ok");
            this.clearFile();
            this.showSpinner = false;
            console.log('an array of ' + array.length + ' number of elements have been proceed');
        }
    }
}
