import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import {
    MatMenuModule,
    MatButtonModule,
    MatIconModule,
    MatToolbarModule,
    MatDialogModule,
    MatListModule,
    MatGridListModule,
    MatRadioModule,
    MatInputModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatSelectModule,
    MatProgressSpinnerModule
} from '@angular/material';

import { MenuComponent } from './menu';
import { FileUploadComponent } from './file-upload';
import { CdkTableModule } from '@angular/cdk/table';
import { YescancelDialogComponent } from './yescancel-dialog/yescancel-dialog.component';


@NgModule({
    imports: [
        CommonModule,
        MatMenuModule,
        MatButtonModule,
        MatIconModule,
        MatToolbarModule,
        MatDialogModule,
        MatListModule,
        MatGridListModule,
        MatRadioModule,
        FormsModule,
        ReactiveFormsModule,
        MatInputModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        CdkTableModule,
        MatSelectModule,
        MatProgressSpinnerModule,
    ],
    exports: [
        MenuComponent,
        FileUploadComponent
    ],
    declarations: [
        MenuComponent,
        FileUploadComponent,
        YescancelDialogComponent
    ],
    entryComponents: [
        YescancelDialogComponent
    ]
})
export class CoreModule { }
