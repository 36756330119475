import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserService } from './services/user.service';
import { LocalStorageService } from './services/localStorage.service';
import { AuthGuard } from './guards/index';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [],
    providers: [
        UserService,
        LocalStorageService,
        AuthGuard
    ]
})
export class SharedModule { }