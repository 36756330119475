import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material';

import { LocalStorageService, SoligasConfig, UserService } from '../../shared';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  @Input() public activeItem: number;
  @Input() public pageTitle: string;
  @Input() public buttonAction: string;
  @Input() public returnButtonAction: string;
  @Input() public returnButtonText: string;

  public userName: string;
  public visible: boolean;
  public role: number;

  constructor(
    private localStorageService: LocalStorageService,
    private router: Router,
    private userService: UserService,
    public snackBar: MatSnackBar,
  ) { }

  public ngOnInit(): void {
    this.getUserName();
  }

  public onVisible(): void {
    this.visible = !this.visible;
  }

  public userLogout(): void {
    this.localStorageService.clear();
    this.router.navigate(['/login']);
  }

  public activateMenu(menu: string, event: Event): void {
    event.preventDefault();
    this.router.navigate([menu]);
  }

  private getUserName(): void {
    let user = this.localStorageService.getItem(SoligasConfig.UserInfoParams.userData);
    user = user.value && JSON.parse(user.value)
      ? JSON.parse(user.value)
      : null;
    this.userName = user.name;
    this.role = user.role_id;
  }

  private openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }
}
