import { Component, OnInit } from '@angular/core';
import { SoligasConfig } from '../shared';

@Component({
  selector: 'app-seguimiento',
  templateUrl: './seguimiento.component.html',
  styleUrls: ['./seguimiento.component.sass']
})
export class SeguimientoComponent implements OnInit {
  public displayedColumns: string[];

  constructor() { }

  public ngOnInit(): void {
   
  }

}
