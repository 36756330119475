import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { LocalStorageService } from '../services/localStorage.service';
import { SoligasConfig } from '../config';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private localStorageService: LocalStorageService
    ) { }

    public canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Promise<boolean> {
        return new Promise((resolve) => {
            const userData = this.localStorageService.getItem(SoligasConfig.UserInfoParams.userData);
            if (!userData || userData.value && !JSON.parse(userData.value).role_id) {
                this.router.navigate(['/login']);
            }
            resolve(true);
        })
    }
}
