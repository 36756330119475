import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { SoligasConfig } from '../shared';

@Component({
  selector: 'app-sgi-documents',
  templateUrl: './sgi-documents.component.html',
  styleUrls: ['./sgi-documents.component.scss']
})
export class SgiDocumentsComponent implements OnInit {

  public financialDocuments: string[];
  public formatosChilco: string[];
  public formatosTalentoHumano: string[];
  public procedimientosFinanciero: string[];
  public procedimientosOperaciones: string[];
  public procedimientosOperacionesInstructivos: string[];
  public procedimientosTalentoHumano: string[];

  constructor(public sanitizer: DomSanitizer) { }

  public ngOnInit(): void {
    this.financialDocuments = SoligasConfig.financialDocs;
    this.formatosChilco = SoligasConfig.formatosChilco;
    this.formatosTalentoHumano = SoligasConfig.formatosTalentoHumano;
    this.procedimientosFinanciero = SoligasConfig.procedimientosFinanciero;
    this.procedimientosOperaciones = SoligasConfig.procedimientosOperaciones;
    this.procedimientosOperacionesInstructivos = SoligasConfig.procedimientosOperacionesInstructivos;
    this.procedimientosTalentoHumano =  SoligasConfig.procedimientosTalentoHumano;

  }

}
