import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AuthGuard } from './shared';
import { SeguimientoComponent } from './seguimiento/seguimiento.component';
import { RevisionComponent } from './revision/revision.component';
import { SgiDocumentsComponent } from './sgi-documents/sgi-documents.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: '', component: LoginComponent },
  { path: "dashboard", component: DashboardComponent, canActivate: [AuthGuard] },
  { path: "seguimiento", component: SeguimientoComponent, canActivate: [AuthGuard] },
  { path: "revision", component: RevisionComponent, canActivate: [AuthGuard] },
  { path: "documents", component: SgiDocumentsComponent, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
