import { Injectable } from '@angular/core';
import { SoligasConfig } from '../config';

@Injectable()
export class LocalStorageService {

    constructor() { }

    public getItem(key: string): Object | any {
        let value: string = sessionStorage.getItem(key);
        return value ? JSON.parse(value) : null;
    }

    public setItem(key: string, value: StorageValueInterface): boolean {
        try {
            sessionStorage.setItem(key, JSON.stringify(value));
            return true;
        } catch (e) {
            console.error(e);
            return false;
        }
    }

    public getUserId(): number {
        let user = this.getItem(SoligasConfig.UserInfoParams.userData);
        user = user.value && JSON.parse(user.value)
            ? JSON.parse(user.value)
            : null;
        return user.user_id;
    }

    public getUserIdentification(): number {
        let user = this.getItem(SoligasConfig.UserInfoParams.userData);
        user = user.value && JSON.parse(user.value)
            ? JSON.parse(user.value)
            : null;
        return user === null ? null : user.identification;
    }

    public removeItem(key: string): void {
        sessionStorage.removeItem(key);
    }

    public clear(): void {
        sessionStorage.clear();
    }

    public isEnabled(): boolean {
        try {
            sessionStorage.setItem("test", "test");
            sessionStorage.removeItem("test");
            return true;
        } catch (e) {
            console.error(e);
            return false;
        }
    }
}
