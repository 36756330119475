import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatDialogRef, MatTableDataSource, MatSnackBar, MatDialog } from '@angular/material';
import { YescancelDialogComponent } from '../core/yescancel-dialog/yescancel-dialog.component';
import { SoligasConfig, UserService } from '../shared';

@Component({
  selector: 'app-revision',
  templateUrl: './revision.component.html',
  styleUrls: ['./revision.component.scss']
})
export class RevisionComponent implements OnInit {

  public dataSource: MatTableDataSource<any>;
  public displayedColumns: string[];
  public showTableFiles: boolean;
  public loadedFiles: [];
  public userList: UserList[];
  public selectedFilter: number;
  public comments: string;

  private dialogRef: MatDialogRef<YescancelDialogComponent>;

  @ViewChild(MatPaginator) public paginator: MatPaginator;
  @ViewChild(MatSort) public sort: MatSort;

  constructor(
    private userService: UserService,
    public snackBar: MatSnackBar,
    public dialog: MatDialog,
  ) { }

  public ngOnInit(): void {
    this.displayedColumns = SoligasConfig.seguimientoColumns;
    this.showTableFiles = false;
    this.loadedFiles = [];
    this.userList = [];
    this.selectedFilter = 0;
    this.comments = "";
    this.loadUsers();
    this.loadSeguimiento();
  }

  public onSelectFile(row: SeguimientoList): void {
    this.userService.loadSeguimientoFileList(row.seg_id, (res: { success: boolean, files: [] }) => {
      if (res) {
        this.loadedFiles = res.files;
        this.comments = row.comment;
      } else {
        this.openSnackBar("Ha ocurrido un error al recuperar los archivos", "ok");
      }
    });
  }

  public onFilterChange(event): void {
    this.selectedFilter = event.value;
    this.loadSeguimiento();
  }

  public downloadFiles(): void {
    const fileProperty = "file";
    for (let i = 0; i <= this.loadedFiles.length - 1; i++) {
      const url = this.loadedFiles[i][fileProperty];
      var a = document.createElement('a');
      a.href = url;
      a.download = url;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  }

  public deleteFile(fileId: number, event: Event): void {
    event.preventDefault();
    this.dialogRef = this.dialog.open(YescancelDialogComponent, {
      disableClose: false
    });
    this.dialogRef.componentInstance.confirmMessage = `Está seguro que desea eliminar el seguimiento?`;

    this.dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.userService.inactiveSeguimiento(fileId, (res: boolean) => {
          this.loadedFiles = [];
          this.comments = "";
          this.loadSeguimiento();
        });
      }
      this.dialogRef = null;
    });
  }

  private loadSeguimiento(): void {
    if (this.selectedFilter !== 0) {
      this.userService.loadSeguimientoByEmployee(this.selectedFilter, (res: { success: boolean, seguimiento: SeguimientoList[] }) => {
        this.setFilesDataSource(res.seguimiento);
        this.setTableOptions();
      });
    } else {
      this.userService.loadSeguimientoAll((res: { success: boolean, seguimiento: SeguimientoList[] }) => {
        this.setFilesDataSource(res.seguimiento);
        this.setTableOptions();
      });
    }

  }

  private setFilesDataSource(seguimiento: SeguimientoList[]): void {
    this.dataSource = new MatTableDataSource(seguimiento);
    this.showTableFiles = seguimiento ? seguimiento.length > 0 : false;
  }

  private setTableOptions(): void {
    setTimeout(() => {
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    });
  }

  private openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }

  private loadUsers(): void {
    this.userService.loadUsers((res: { success: boolean, users: UserList[] }) => {
      if (res.success) {
        this.userList = res.users;
      }
    });
  }
}
