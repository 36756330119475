import { HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';

class SoligasConfig {
    public static apiUrl = environment.apiUrl;

    public static parseHeaders = {
        headers: new HttpHeaders({
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        })
    };

    public static skipHeaders = {
        headers: new HttpHeaders({
            'X-Skip-Interceptor': ''
        })
    };

    public static UserInfoParams = {
        name: "name",
        email: "email",
        role: "role",
        id: "id",
        userData: "userData"
    }

    public static routesParams = {
        staffId: "staffId",
        promoterId: "promoterId",
        venueId: "venueId",
        venueConfId: "venueConfId",
        eventId: "eventId"
    }

    public static formStatuses = {
        valid: "VALID",
        invalid: "INVALID"
    };

    public static defaultValues = {
        empty: {},
        null: null,
        notValue: "",
        undefined: undefined
    };

    public static httpAnswer = {
        true: "true",
        false: "false"
    };

    public static fileManagerTypes = {
        png: 'png',
        jpg: 'jpg',
        pdf: 'pdf',
        xls: 'xls',
        word: 'word'
    };

    public static seguimientoColumns = [
        'seg_id',
        'fecha',
        'comentario',
        'empleado',
        'email',
        'delete'
    ];

    public static financialDocs = [
        "ENTREGA DE VARIOS TANQUES.pdf",
        "FR-03 MEDICION DE ESPESORES TAPAS.pdf",
        "FR-04 MEDICIÓN DE ESPESORES TANQUES Y CISTERNAS..pdf",
        "FR-06 INFORME DE PROYECTO.pdf",
        "FR-08 MEDICIÓN ESPESORES TANQUES 120 gal.pdf",
        "FR-09 MEDICIÓN ESPESORES TANQUES 120 gal AMERICANOS.pdf",
        "FR-12 PRUEBA HIDROSTÁTICA TANQUES.pdf",
        "FR-20 AFORO PUNTO A PUNTO .pdf",
        "FR-25 ACTA DE ENTREGA.pdf",
        "FR-33 SOLICITUD DE MATERIALES.pdf",
        "FR-44 AFORO VOLUMÉTRICO.pdf",
        "FR-47 PRUEBA HIDROSTÁTICA TUBERIAS.pdf",
        "FR-49 MEDICIÓN ESPESORES TUBERIA.pdf",
        "FR-50 MEMORIA  FÍSICA DE MEDICIÓN DE ESPESORES A TUBERIA TRAMOS REDUCIDOS.pdf",
        "FR-51 MEDICIÓN DE ESPESORES EN TANQUES ESTACIONARIOS DE GRAN CAPACIDAD.pdf",
        "FR-58 ACTA DE ENTREGA REPARACIÓN POR TRAMOS.pdf",
        "FR-62 SEGUIMIENTO DIARIO A OT Y EJECUCION DE PROYECTOS.pdf",
        "FR-64 INSPECCION VISUAL EXTERNA TANQUES 120 gal.pdf",
        "FR-65 INSPECCION VISUAL EXTERNA TANQUES.pdf",
        "FR-66 VERIFICACION DE REDES (1).pdf",
        "FR-66 VERIFICACION DE REDES.pdf",
        "FR-67 LISTA DE CHEQUEO INSTALACIONES.pdf",
        "FR-68 REPORTE CAMBIO Y DEVOLUCIÓN DE  ACCESORIOS.pdf",
        "FR-69 PRUEBA DE HERMETICIDAD.pdf",
        "FR-70 INSPECCION VISUAL EXTERNA CISTERNAS.pdf",
        "FR-71 INSPECCION VISUAL INTERNA TANQUES - CISTERNAS.pdf",
        "FR-74 AVALÚOS CISTERNAS.pdf",
        "FR-75 REPORTE VARIOS.pdf",
        "FR-77 INSPECCIÓN ANUAL TANQUES.pdf",
        "FR-78 ORDEN DE TRABAJO.pdf",
        "FR-81 INSPECCION VISUAL EXTERNA A PLANTAS MÓVILES.pdf",
        "FR-82 AFORO MATEMATICO.pdf",
        "FR-87 LISTA DE INSPECCION DEPOSITOS, EXPENDIOS, PUNTOS DE VENTA.pdf",
        "FR-88 INSPECCIÓN DE PLANTAS V07.pdf",
        "FR-92 CHECK LIST TRABAJOS DE CAMPO REALIZADOS.pdf",
        "FR-94 PARTICULAS MAGNETICAS.pdf",
        "FR-95 PRUEBA HIDRAULICA .pdf",
        "FR-96 BOMBAS.pdf",
        "FR-97 MANTENIMIENTO A PLANTAS MÓVILES.pdf",
        "FR-99 COMPRESORES.pdf",
        "FR-100 TUBERÍAS.pdf",
        "FR-103 RECEPCION DE TANQUES.pdf",
        "FR-109 VERIFICACIÓN DEL PLANO.pdf",
        "FR-110 PRUEBA NEUMATICA.pdf",
        "FR-111 VERIFICACIÓN DE VÁLVULAS DE ALIVIO.pdf",
        "FR-112 VERIFICACIÓN ROTOGAGE.pdf",
        "FR-113 MANTENIMIENTO CISTERNAS.pdf",
        "FR-122 CONTROL DE ORDENES DE TRABAJO.pdf",
        "FR-133 MANTENIMIENTO TANQUES DE 120 GALONES AMERICANOS.pdf",
        "FR-134 MANTENIMIENTO TANQUES DE 120 GALONES.pdf",
        "FR-135 MANTENIMIENTO TANQUES.pdf",
        "FR-136 MANTENIMIENTO TANQUES RESOLUCION 40245.pdf"
    ];

    public static formatosChilco = [
        "F0150__1__Formato_de_capacitacion.pdf",
        "F0700__1__Revision_de_acometida_del_cliente.pdf",
        "F0701__1__Verificacion_red_interior_del_cliente_VRI.pdf",
        "F0702__1___Verifica_general_instalacion_del_cliente_VIC.pdf",
        "F0704__1__Revision_parcial_sistema_de_abastecimiento_GLP_en_usuario_granel.pdf",
        "F0705__1__Revision_total_sistema_de_abastecimiento_glp_en_usuario_granel.pdf",
        "F0706__1__Visita_tecnica_a_clientes_o_proyectos.pdf",
        "F0708__2__Entrega_tanques_estacionarios.pdf",
        "F0709__1__Servicio_de_emergencia_tecnica_SET.pdf",
        "F0710__1__Acta_de_baja_de_tanques_estacionarios.pdf",
        "F0711__1__Lista_de_verificacion_izaje_de_cargas.pdf",
        "F0713__1__Documento_terminacion_de_obra_DTO.pdf"
    ];

    public static formatosTalentoHumano = [
        "FR-02 ASISTENCIA A CAPACITACIÓN.pdf",
        "FR-98 ENTREGA DE DOTACIÓN Y ELEMENTOS DE SEGURIDAD INDUSTRIAL.pdf",
        "FR-116 PERMISO DE TRABAJO  DE TAREAS DE ALTO RIESGO.pdf",
        "FR-120 ATS 2.pdf",
        "FR-120 ATS.pdf",
        "FR-127 LISTA DE CHEQUEO PARA TRABAJO EN ALTURAS.pdf",
        "FR-128 LISTA DE CHEQUEO ESPACIOS CONFINADOS.pdf",
        "FR-129 LISTA DE CHEQUEO PARA ARNÉS DE SEGURIDAD.pdf",
        "FR-130 PERMISO TRABAJOS EN CALIENTE.pdf",
        "FR-131 PERMISOS DE TRABAJO SEGURO.pdf",
        "FR-132 INSPECCIÓN DE EPP.pdf",
        "FR-133 INSPECCION PULIDORA.pdf"
    ];

    public static procedimientosFinanciero = ["IT-06 CONTROL DE VIATICOS..pdf"];
    public static procedimientosOperaciones = [
        "PC-10 MANTENIMIENTO E INSPECCION.pdf",
        "PC-12 AFORO.pdf",
        "PC-13 MONTAJES.pdf",
        "PROCEDIMIENTO HERMETICIDAD.pdf",
    ];

    public static procedimientosOperacionesInstructivos = [
        "IT-01 AFORO.pdf",
        "IT-02 PRUEBA HIDROSTÁTICA.pdf",
        "IT-03 DRENAJE DE TANQUES.pdf",
        "IT-04 MEDICION DE ESPESORES.pdf",
        "IT-05 SOLDADURA TANQUE.pdf",
        "IT-05 SOLDADURA TUBERIA.pdf",
        "IT-07 INSPECCION VISUAL.pdf",
        "IT-08 PINTURA TANQUES -CISTERNAS Y TUBERIAS.pdf",
        "IT-09 ENTREGA DE DOCUMENTACIÓN Y DIGITACION DE INFORMES.pdf",
        "IT-10 MTTO PREVENTIVO Y-O CORRECTIVO DE VALVULA DE CORTE.pdf",
        "IT-12 PROGRAMA MANEJO SEGURO DE EQUIPOS Y HERRAMIENTAS V1.pdf",
        "IT-14 CAMBIO DE ACCESORIOS.pdf",
        "IT-16 QUEMA DE VAPORES INTERVENCION DE TANQUE .pdf",
        "IT-19 LAVADO INTERNO DE CISTERNAS.pdf",
        "IT-25 FLUJOGRAMA MANTENIMIENTO TANQUES ESTACIONARIOS O TRANSPORTE.pdf",
        "IT-26 MONTAJE PLANTAS MÓVILES.pdf",
        "IT-27 CISTERNAS.pdf",
        "IT-28 ESPACIOS CONFINADOS.pdf",
        "IT-29 PROCEDIMIENTO HERMETICIDAD PARA VALVULAS DE FONDO Y DE CORTE.pdf",
        "IT-30 QUEMA DE VAPORES CISTERNAS VOLCADAS.pdf"
    ];


    public static procedimientosTalentoHumano = [
        "REGLAMENTO HIGIENE Y SEGURIDAD INDUSTRIAL (2018-SEDE NUEVA).pdf",
        "REGLAMENTO INTERNO DE TRABAJO.pdf"
    ];


    public static maxFileSize = 3072;
}

export { SoligasConfig }