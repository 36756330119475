import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { SoligasConfig } from '../config/index';
import { LocalStorageService } from './localStorage.service';

@Injectable()
export class UserService {
    private loginApiUrl = `${SoligasConfig.apiUrl}/login`;
    private colillasApiUrl = `${SoligasConfig.apiUrl}/colillas`;
    private seguimientoUploadApiUrl = `${SoligasConfig.apiUrl}/seguimientoupload`;
    private seguimientoloadApiUrl = `${SoligasConfig.apiUrl}/seguimientoall`;
    private seguimientoFileApiUrl = `${SoligasConfig.apiUrl}/segfile`;
    private seguimietoFilesListApiUrl = `${SoligasConfig.apiUrl}/seguimientofiles`;
    private userListApiUrl = `${SoligasConfig.apiUrl}/users`;
    private laboralLetterApiUrl = `${SoligasConfig.apiUrl}/laboralletter`;
    private seguimientoByUserApiUrl = `${SoligasConfig.apiUrl}/seguimientobyuser`;
    private seguimientoInactiveApiUrl = `${SoligasConfig.apiUrl}/seguimientoinactive`;

    constructor(private http: HttpClient,
        private localStorageService: LocalStorageService
    ) {
    }

    public login(userName: string, password: string, callback: (result: UserLoginResponse) => void): void {
        this.http.post(this.loginApiUrl
            , { username: userName, password: password }
            , SoligasConfig.parseHeaders).pipe().subscribe((res: UserLoginResponse) => {
                if (res.success) {
                    this.localStorageService.setItem(SoligasConfig.UserInfoParams.userData, { value: JSON.stringify(res.user) });
                }
                callback(res);
            });
    }

    public getColillas(user_Id: number, callback: (result: []) => void): void {
        this.http.post(this.colillasApiUrl
            , { user_Id: user_Id }
            , SoligasConfig.parseHeaders).pipe().subscribe((res: UserColillasResponse) => {
                callback(res.colillas);
            });
    }

    public uploadSeguimiento(file: Seguimiento, callback: (res: { success: boolean, insertId: number }) => void): void {
        this.http.post(this.seguimientoUploadApiUrl
            , {
                comment: file.description,
                user_Id: this.localStorageService.getUserId()
            }
            , SoligasConfig.parseHeaders).pipe().subscribe((res: { success: boolean, insertId: number }) => {
                callback(res);
            });
    }

    public uploadSeguimientoFile(file: string, id: number, callback: (res: { success: boolean, insertId: number }) => void): void {
        this.http.post(this.seguimientoFileApiUrl
            , {
                file: file,
                seg_id: id
            }
            , SoligasConfig.parseHeaders).pipe().subscribe((res: { success: boolean, insertId: number }) => {
                callback(res);
            });
    }

    public loadSeguimientoFileList(id: number, callback: (res: { success: boolean, files: [] }) => void): void {
        this.http.post(this.seguimietoFilesListApiUrl
            , {
                seg_id: id
            }
            , SoligasConfig.parseHeaders).pipe().subscribe((res: { success: boolean, files: [] }) => {
                callback(res);
            });
    }

    public loadSeguimientoAll(callback: (res: { success: boolean, seguimiento: SeguimientoList[] }) => void): void {
        this.http.post(this.seguimientoloadApiUrl
            , {
                user_Id: this.localStorageService.getUserId()
            }
            , SoligasConfig.parseHeaders).pipe().subscribe((res: { success: boolean, seguimiento: SeguimientoList[] }) => {
                callback(res);
            });
    }

    public loadSeguimientoByEmployee(employ_id: number, callback: (res: { success: boolean, seguimiento: SeguimientoList[] }) => void): void {
        this.http.post(this.seguimientoByUserApiUrl
            , {
                user_id: employ_id
            }
            , SoligasConfig.parseHeaders).pipe().subscribe((res: { success: boolean, seguimiento: SeguimientoList[] }) => {
                callback(res);
            });
    }

    public inactiveSeguimiento(seg_id: number, callback: (res: boolean) => void): void {
        this.http.post(this.seguimientoInactiveApiUrl
            , {
                seg_id: seg_id
            }
            , SoligasConfig.parseHeaders).pipe().subscribe((res: { success: boolean }) => {
                callback(res.success);
            });
    }

    public loadUsers(callback: (res: { success: boolean, users: UserList[] }) => void): void {
        this.http.post(this.userListApiUrl
            , SoligasConfig.parseHeaders).pipe().subscribe((res: { success: boolean, users: UserList[] }) => {
                callback(res);
            });
    }

    requestLaboralLetter(callback: (res: { success: boolean }) => void): void {
        const body = ` El empleado con Cc. ${this.localStorageService.getUserIdentification()} ha realizado solicitud de carta laboral`;
        this.http.post(this.laboralLetterApiUrl
            , {
                subject: `Solicitud Carta Laboral`,
                message: body
            }
            , SoligasConfig.parseHeaders).pipe().subscribe(() => {
                callback({ success: true });
            });
    }

    public logout(callback: (res: httpAnswer) => void): void {
        // this.http.get(this.userLogoutApiUrl, CmsConfig.skipHeaders).toPromise().then((res: httpAnswer) => {
        //   callback(res);
        // });
    }
}
