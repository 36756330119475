export class User {
    public email: string;
    public identification: string;
    public mobile: string;
    public name: string;
    public passwr: string;
    public phone: string;
    public role_id: number;
    public user_id: number;

    public constructor(fields?: any) {
        this.email = "";
        this.identification = "";
        this.mobile = "";
        this.name = "";
        this.passwr = "";
        this.phone = "";
        this.role_id = 0;
        this.user_id = 0;

        if (fields) {
            Object.assign(this, fields);
        }
    }
}