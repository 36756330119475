import { Component, OnInit } from '@angular/core';

import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-yescancel-dialog',
  templateUrl: './yescancel-dialog.component.html',
  styleUrls: ['./yescancel-dialog.component.scss']
})
export class YescancelDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<YescancelDialogComponent>) { }
  public confirmMessage: string;
  public configTittle: string;

  public ngOnInit(): void {
    this.configTittle = this.configTittle === undefined ? "Confirm" : this.configTittle;
  }

}
