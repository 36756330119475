import { Component, OnInit } from '@angular/core';
import { UserService, LocalStorageService, SoligasConfig } from '../shared';
import { DomSanitizer } from '@angular/platform-browser';
import { MatSnackBar } from '@angular/material';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  public colillas: [];
  public showSpinner: boolean;
  public identificacion: number;

  constructor(
    private userService: UserService,
    private localStorageService: LocalStorageService,
    public sanitizer: DomSanitizer,
    public snackBar: MatSnackBar,
  ) { }

  public ngOnInit(): void {
    this.showSpinner = false;
    this.identificacion =  this.localStorageService.getUserIdentification();
    this.loadColillas();
  }

  private loadColillas(): void {
    let userId = this.localStorageService.getUserId();
    this.userService.getColillas(userId, (res: []) => {
      this.colillas = res;
    });
  }

  public downloadFile(file: number): void {
    var win = window.open();
    win.document.write('<iframe src="../../assets/colillas/' + file + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>');
  }

  public requestLaboralLetter() {
    this.showSpinner = true;
    this.userService.requestLaboralLetter((res: { success: boolean }) => {
      if (res.success) {
        setTimeout(() => {
          this.openSnackBar("Su solicitud ha sido enviada recibirá respuesta en 48h", "ok");
          this.showSpinner = false;
        }, 500);
      } else {
        this.openSnackBar("Ocurrió un problema procesando su solicitud contacte sistemas", "ok");
      }
    });
  }

  private openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }
}
